import ionRangeSlider from 'ion-rangeslider';

export default class RangeSliderComponent {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.rangeValue = this.baseElement.find('.range-value');
    this.rangeButtons = this.baseElement.find('input[type="radio"]');
    this.rangeSider = this.baseElement.find('.vsemsim-range-slider');

    this.rangeSider.ionRangeSlider({
      skin: 'vsemsim',
      onChange: (data) => {
        let radioButton = this.rangeButtons.eq(data.from);
        radioButton.prop('checked', true);
        this.rangeValue.text(radioButton.parent().text());
      },
    });

    this.rangeButtons.on('change', () => {
      this.rangeButtons.each((index) => {
        if (this.rangeButtons[index].checked) {
          this.rangeSider.data('ionRangeSlider').update({ from: index });
          this.rangeValue.text($(this.rangeButtons[index]).parent().text());
        }
      });
    });
  }
}
