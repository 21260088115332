import lodashTemplate from 'lodash/template';
import tariffTemplate from '../templates/tariff.ejs';
import tariffListTemplate from '../templates/tariff_list.ejs';

import forEach from 'lodash/forEach';
import AddToCartComponent from './AddToCartComponent';
import OneClickPurchase from './OneClickPurchase';
import AnimationComponent, { AnimationTypes } from './AnimationComponent';
import { toggleDocumentScrollBlocker } from '../utils';

const FORM_VALUE_SEPARATOR = ';';

export default class TariffFilterComponent {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.isLoading = false;

    this.paginators = [];
    this.prevPaginator = $(this.baseElement.attr('data-prev-selector'));
    this.nextPaginator = $(this.baseElement.attr('data-next-selector'));
    this.paginatorsCounter = $(this.baseElement.attr('data-paginator-count-selector'));
    this.paginateBy = this.baseElement.attr('data-paginate-by');

    this.form = this.baseElement.find('form');
    this.sortField = this.baseElement.find(this.baseElement.attr('data-sort-field'));

    this.targetBody = this.baseElement.find('.tarif-list');
    this.animationContainer = this.baseElement.find(
      this.baseElement.attr('data-animation-container')
    );

    this.submitters.on('click', (e) => {
      e.preventDefault();
      this.reload();
    });
    this.sortField.on('change', () => {
      this.reload();
    });
  }

  reload() {
    if (this.isLoading) return;
    this.isLoading = true;

    this.targetBody.empty();
    let animation = new AnimationComponent(this.animationContainer, AnimationTypes.DEFAULT);

    this.updateHistory();
    this.sendAjax()
      .then((data) => {
        let html = this.renderTariffs(data.results);
        this.targetBody.html(html);
        this.updatePaginator(data);
        // После загрузки страницы у нас сбросились компоненты.
        this.targetBody.find('.add-to-cart-component').each((index, htmlElement) => {
          new AddToCartComponent($(htmlElement));
        });
        this.targetBody.find('.one-click-purchase').each((index, htmlElement) => {
          new OneClickPurchase(htmlElement);
        });
        let body = $('body');
        if (body.hasClass('filter_open')) {
          toggleDocumentScrollBlocker();
          body.removeClass('filter_open');
        }
      })
      .fail((error) => {
        throw error;
      })
      .always(() => {
        this.isLoading = false;
        animation.destroy();
      });
  }

  renderTariffs(tariffs) {
    let tariffsList = [];
    for (let i = 0; i < tariffs.length; i++) {
      tariffsList.push(this.preparedObjTemplate({ tariff: tariffs[i] }));
    }

    return this.preparedListTemplate({ tariffs: tariffsList });
  }

  get preparedObjTemplate() {
    return lodashTemplate(tariffTemplate, {
      imports: {
        forEach: forEach,
      },
    });
  }

  get preparedListTemplate() {
    return lodashTemplate(tariffListTemplate, {
      imports: {
        forEach: forEach,
      },
    });
  }

  get submitters() {
    return this.form.find(':submit');
  }

  updatePaginator(data) {
    if (!data.next) {
      this.paginatorsCounter.html('1/1');
      this.prevPaginator.removeAttr('href');
      this.prevPaginator.attr('disabled', true);

      this.nextPaginator.removeAttr('href');
      this.nextPaginator.attr('disabled', true);
    } else {
      // округляем в большую сторону
      this.paginatorsCounter.html(`1/${Math.ceil(data.count / this.paginateBy)}`);
      this.prevPaginator.removeAttr('href');
      this.prevPaginator.attr('disabled', true);

      let windowURL = new URL(window.location);
      let nextURL = new URL(data.next);
      let nextPage = nextURL.searchParams.get('page');

      windowURL.searchParams.set('page', nextPage);
      this.nextPaginator.attr('href', windowURL.href.replace(windowURL.origin, ''));
    }
  }

  updateHistory() {
    // Берем текущий URL
    let url = new URL(window.location);
    // Подставляем в него параметры запроса
    url.search = new URLSearchParams(this.searchParams).toString();
    // Пушим в историю браузера, кнопка назад должна сбросить текущий фильтр
    window.history.replaceState(this.searchParams, window.document.title, url);
  }

  get url() {
    let actionURL = new URL(window.location.origin + this.form.attr('action'));
    actionURL.search = new URLSearchParams(this.searchParams).toString();
    return actionURL;
  }

  get searchParams() {
    // Метод сериализации формы в параметры URL адреса
    let paramsArray = this.form.serializeArray();

    let paramsDict = {};
    for (let i = 0; i < paramsArray.length; i++) {
      let name = paramsArray[i].name;
      let value = paramsArray[i].value;

      if (name in paramsDict) {
        paramsDict[name] = `${paramsDict[name]}${FORM_VALUE_SEPARATOR}${value}`;
      } else {
        paramsDict[name] = value;
      }
    }

    let sortFieldName = this.sortField.attr('name');
    if (sortFieldName) {
      paramsDict[sortFieldName] = this.sortField.val();
    }

    return $.param(paramsDict);
  }

  sendAjax() {
    return $.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'json',
      encode: true,
      processData: false,
      contentType: false,
    });
  }
}
