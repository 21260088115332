import Swiper from 'swiper/bundle';

const sliderModule = {
  init: () => {
    sliderModule.regular();
    sliderModule.responsive();
  },
  regular: () => {
    // swipers
    // https://github.com/nolimits4web/Swiper/issues/2350
    setTimeout(function() {
      new Swiper('.swiper-tarifs', {
        slidesPerView: 'auto',
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          // when window width is >= 980px
          980: {
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      });
    }, 10);

    new Swiper('.swiper-review', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 980px
        980: {
          spaceBetween: 20,
        },
      },
    });

    new Swiper('.swiper-contacts', {
      slidesPerView: 1,
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    new Swiper('.swiper-operator', {
      slidesPerView: 2,
      spaceBetween: 6,
      freeMode: true,
      pagination: {
        el: '.swiper-operator-wrapper .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-operator-wrapper .swiper-button-next',
        prevEl: '.swiper-operator-wrapper .swiper-button-prev',
      },
      breakpoints: {
        375: {
          spaceBetween: 10,
        },
        440: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        740: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        980: {
          spaceBetween: 18,
          slidesPerView: 4,
        },
        1200: {
          spaceBetween: 18,
          slidesPerView: 5,
        },
      },
    });

    new Swiper('.swiper-product', {
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
  responsive: () => {
    var breakpoint = window.matchMedia('(min-width: 1200px)');
    var mySwiper;

    var enableSwiper = function() {
      mySwiper = new Swiper('.swiper-tarifs-destroy-desktop', {
        slidesPerView: 'auto',
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          980: {
            spaceBetween: 20,
          },
        },
      });
    };

    function breakpointChecker() {
      if (breakpoint.matches) {
        if (mySwiper !== undefined) {
          mySwiper.destroy(true, true);
        }
      } else {
        enableSwiper();
      }
    }
    breakpoint.addEventListener('change', breakpointChecker);
    breakpointChecker();
  },
};

export default sliderModule;
