import { Component, Fragment, h } from 'preact';
import { createPortal } from 'preact/compat';
import CartItemComponent from './CartItemComponent';
import Cookies from 'js-cookie';
import DeliveryComponent from './DeliveryComponent';
import { prepareTariffForDataLayer, pushOrderToDataLayer, pushTariffsToDataLayer } from '../utils';
import { setCartButtonsEmpty, setCartButtonsNonEmpty } from '../components/CartButton';

// Описание интерфейсов по принципу TS, чтобы не забыть структуру.
// interface Tariff {
//   id: int,
//   image: str|null,
//   name: str,
//   order_price: int,
// }
//
// interface CartItem {
//   id: int,
//   count: int,
//   tariff: Tariff,
// }
//
// interface DeliveryType {
//   id: int,
//   is_active: bool,
//   name: str,
//   price: number,
// }
//
// interface PaymentType {
//    int: int,
//    is_active: bool,
//    name: str
// }
//
// interface CartData {
//   id: int,
//   session_key: string,
//   items: CartItem
// }

export default class CartComponent extends Component {
  constructor(props) {
    super(props);
    // Расчет суммы корзины
    let cartSum = 0;
    let preparedTariffs = props.data.items.map((cartItem) => {
      cartSum += cartItem.tariff.connection_price * cartItem.count;
      return prepareTariffForDataLayer(cartItem.tariff, cartItem.count);
    });
    pushTariffsToDataLayer('checkout', preparedTariffs);
    // Выбор доставки, по умолчанию(первый инпут) и расчет цены.
    this.props.delivery_price = props.data.delivery_types ? props.data.delivery_types[0].price : 0;

    let defaultPaymentType;
    if (props.data.payment_types) {
      defaultPaymentType = props.data.payment_types.filter((pt) => pt.default === true)[0];
    }

    this.state = {
      cartData: props.data,
      deliveryForm: {
        delivery_type: props.data.delivery_types ? props.data.delivery_types[0].id.toString() : '',
        payment_type: defaultPaymentType ? defaultPaymentType.id.toString() : '',
      },
      hasCleanItems: props.hasCleanItems,
      cartSum: cartSum,
      withDeliveryPrice: cartSum + this.props.delivery_price,
    };

    this.onCountChange = this.onCountChange.bind(this);
    this.onDeleteChange = this.onDeleteChange.bind(this);
    this.deliveryFormHandleChange = this.deliveryFormHandleChange.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateCartButtonsIfNeeded(prevState);
  }

  updateCartButtonsIfNeeded(prevState) {
    let isEmpty = this.state.cartData.items.length === 0;
    let wasEmpty = prevState.cartData.items.length === 0;
    if (isEmpty && !wasEmpty) {
      setCartButtonsEmpty();
    } else if (!isEmpty && wasEmpty) {
      setCartButtonsNonEmpty();
    }
  }

  calculateCart() {
    let sum = 0;
    this.state.cartData.items.map((cartItem) => {
      sum += cartItem.tariff.connection_price * cartItem.count;
    });
    return sum;
  }

  deliveryFormHandleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    let delivery_price = null;
    if (name === 'delivery_type') {
      let id = parseInt(event.target.value, 10);
      delivery_price = this.state.cartData.delivery_types.filter((item) => item.id === id)[0].price;
    }

    this.setState((oldState) => {
      return {
        ...oldState,
        deliveryForm: {
          ...oldState.deliveryForm,
          [name]: value,
        },
        errors: {
          ...oldState.errors,
          [name]: [],
        },
        withDeliveryPrice:
          delivery_price != null
            ? oldState.withDeliveryPrice - this.props.delivery_price + delivery_price
            : oldState.withDeliveryPrice,
      };
    });
    this.props.delivery_price = delivery_price;
  }

  onCountChange(e, item) {
    if (e.target.value === '') {
      return;
    }
    let oldPrice = item.tariff.connection_price * item.count;
    let newPrice = 0;
    this.setState((oldState) => {
      return {
        ...oldState,
        cartData: {
          ...oldState.cartData,
          items: oldState.cartData.items.map((oldItem) => {
            if (oldItem.id === item.id) {
              item.count = e.target.value;
              newPrice = e.target.value * item.tariff.connection_price;
              return item;
            }
            return oldItem;
          }),
        },
        cartSum: oldState.cartSum - oldPrice + newPrice,
        withDeliveryPrice: oldState.withDeliveryPrice - oldPrice + newPrice,
      };
    });

    $.ajax({
      type: 'PATCH',
      url: item.update_link,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data: JSON.stringify({ count: item.count }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      encode: true,
      processData: false,
    });
  }

  onDeleteChange(e, item) {
    let oldPrice = item.tariff.connection_price;
    this.setState((oldState) => {
      return {
        ...oldState,
        cartData: {
          ...oldState.cartData,
          items: oldState.cartData.items.filter((oldItem) => oldItem.id !== item.id),
        },
        cartSum: oldState.cartSum - oldPrice,
        withDeliveryPrice: oldState.withDeliveryPrice - oldPrice,
      };
    });

    $.ajax({
      type: 'DELETE',
      url: item.delete_link,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      dataType: 'json',
      encode: true,
      processData: false,
      contentType: false,
    });
  }

  handleOrder(e) {
    e.preventDefault();

    $.ajax({
      type: 'POST',
      url: this.props.orderURL,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data: JSON.stringify(this.state.deliveryForm),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      encode: true,
      processData: false,
    })
      .done((data) => {
        if (data.status === 'success') {
          this.setState((oldState) => {
            return {
              ...oldState,
              successMessage:
                'Спасибо, ваш заказ принят! Скоро с вами свяжется менеджер для подтверждения заказа.',
            };
          });
          let preparedTariffs = this.props.data.items.map((cartItem) => {
            return prepareTariffForDataLayer(cartItem.tariff, cartItem.count);
          });
          pushOrderToDataLayer(preparedTariffs, data.order_id, data.order_sum);
        }
      })
      .fail((errors) => {
        this.setState((oldState) => {
          return {
            ...oldState,
            errors: errors.responseJSON,
          };
        });
      });
  }

  render() {
    let deliveryComponent = window.document.getElementById('cart-form');
    if (this.state.successMessage) {
      return <p>{this.state.successMessage}</p>;
    }
    if (this.state.cartData.items.length === 0) {
      if (this.state.hasCleanItems) {
        return <p>Часть тарифов перешла в архив. Теперь ваша корзина пуста!</p>;
      }
      return <p>Ваша корзина пуста!</p>;
    }
    return (
      <Fragment>
        <div class="inner-content-head">
          <h1>Корзина</h1>
        </div>
        {this.state.cartData.items.map((cartItem) => {
          return (
            <CartItemComponent
              item={cartItem}
              sprite={this.props.sprite}
              onCountChange={this.onCountChange}
              onObjectDelete={this.onDeleteChange}
            />
          );
        })}
        <div class="my-bag-row">
          {this.state.hasCleanItems ? (
            <div class="my-bag-col">Часть тарифов перешла в архив, корзина была обновлена!</div>
          ) : null}
          <div class="my-bag-col my-bag-col_sum">Итого: {this.state.cartSum} ₽</div>
        </div>
        {createPortal(
          <DeliveryComponent
            handleChange={this.deliveryFormHandleChange}
            handleCreateOrder={this.handleOrder}
            state={this.state}
          />,
          deliveryComponent
        )}
      </Fragment>
    );
  }
}
