export function prepareTariffForDataLayer(tariff, quantity = 1) {
  return {
    name: tariff.name,
    id: tariff.id,
    price: tariff.connection_price,
    brand: tariff.provider.name,
    category: 'Тариф',
    quantity: quantity,
  };
}

export function pushTariffsToDataLayer(method, tariffs) {
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        [method]: {
          products: tariffs,
        },
      },
    });
  }
}

export function pushOrderToDataLayer(items, orderID, orderSum) {
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: orderID,
            affiliation: "vsemsim.ru",
            revenue: orderSum,
          },
          products: items,
        },
      },
    });
  }
}

export function toggleDocumentScrollBlocker() {
  let body = document.body;
  if (body.classList.contains('document-scroll-blocker')) {
    body.classList.remove('document-scroll-blocker');
  } else {
    body.classList.add('document-scroll-blocker');
  }
}
