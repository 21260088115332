const cartButtons = [];

export function setCartButtonsNonEmpty() {
  for (const cartButton of cartButtons) {
    cartButton.nonEmpty = true;
  }
}

export function setCartButtonsEmpty() {
  for (const cartButton of cartButtons) {
    cartButton.nonEmpty = false;
  }
}

export default class CartButton {
  constructor(baseElement) {
    this.baseElement = baseElement;
    cartButtons.push(this);
  }

  getNonEmptyIcon() {
    return this.baseElement.querySelector('.cart-button__non-empty-icon');
  }

  get nonEmpty() {
    return !!this.getNonEmptyIcon();
  }

  set nonEmpty(value) {
    if (value && !this.nonEmpty) {
      let nonEmptyIcon = document.createElement('span');
      nonEmptyIcon.classList.add('cart-round-notif', 'cart-button__non-empty-icon');
      this.baseElement.prepend(nonEmptyIcon);
    } else if (!value && this.nonEmpty) {
      this.getNonEmptyIcon().remove();
    }
  }
}
