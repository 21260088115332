import sentry from './components/sentry';
sentry.init();

import $ from 'jquery';

import sliders from './modules/sliders.js';
import rating from './modules/rating.js';
import sticky from './modules/sticky.js';

import layout from './components/layout.js';
import FormComponent from './components/FormComponent';
import TariffFilterComponent from './components/TariffFilterComponent';
import AddToCartComponent from './components/AddToCartComponent';
import { renderCartComponent } from './jsx';
import YandexMap from './components/YandexMap';
import reviewsTemplate from './templates/review.ejs';
import simpleReviewsTemplate from './templates/simple_reviews_template.ejs';
import GenericLoadMoreReviewsComponent from './components/GenericLoadMoreReviewsComponent';
import CalculatorFilterComponent from './components/CalculatorFilterComponent';
import RangeSliderComponent from './components/RangeSliderComponent';
import { initMagnificPopup } from './components/magnific-popup';
import OneClickPurchase, { initOneClickPurchaseModal } from './components/OneClickPurchase';
import CartButton from './components/CartButton';

import 'jquery-mask-plugin';

import './extra.js';
import './modules/clicks.js';

$('.cart-component').each((index, htmlElement) => {
  renderCartComponent(htmlElement);
});

$(() => {
  layout.init();
  sliders.init();
  rating.init();

  sticky.init();
});

$('select').each((index, htmlElement) => {
  let element = $(htmlElement);
  let optionSelected = element.find('option:selected');

  if (optionSelected.is(':disabled')) {
    element.css({ color: '#818181' });
  }
  element.on('change', () => {
    element.css({ color: '#2D2D2D' });
  });
});

$('.form-component').each((index, htmlElement) => {
  new FormComponent($(htmlElement));
});

$('.tariff-filter-component').each((index, htmlElement) => {
  new TariffFilterComponent($(htmlElement));
});

$('.filter-item__tt').each((index, htmlElement) => {
  let element = $(htmlElement);
  let elementData = element.data();
  let searchParams = new URLSearchParams(window.location.search);
  let fieldName = elementData.field_name;
  let values = searchParams.get(fieldName);
  if (values) {
    values = values.split(';');
    element
      .parent()
      .find('input[type="radio"], input[type="checkbox"]')
      .each((index, htmlElement) => {
        // URL должен включать все значения в поле
        let elementValues = htmlElement.value.split(';');
        for (let i = 0; i < elementValues.length; i++) {
          const elementValue = elementValues[i];
          if (!values.includes(elementValue)) {
            return;
          }
        }

        htmlElement.setAttribute('checked', true);
      });
  }
  if (
    element.siblings('.filter-item-options').find('input:checked').length > 0 ||
    elementData.has_open === 'True'
  ) {
    element.toggleClass('open').siblings('.filter-item-options').slideToggle();
  }
});

$('.js-load-from-url').each((index, input) => {
  let inputName = input.getAttribute('name');
  if (!inputName) {
    return;
  }

  let searchParams = new URLSearchParams(window.location.search);
  let value = searchParams.get(inputName);
  if (value) {
    input.value = value;
  }
});

$('.add-to-cart-component').each((index, htmlElement) => {
  new AddToCartComponent($(htmlElement));
});

initOneClickPurchaseModal();

$('.one-click-purchase').each((index, htmlElement) => {
  new OneClickPurchase(htmlElement);
});

$('.cart-button').each((index, htmlElement) => {
  new CartButton(htmlElement);
});

$('.js-validate-form').each((index, htmlElement) => {
  $(htmlElement).validate({
    rules: {
      email: {
        email: true,
      },
    },
  });
});

$('.yandex-map').each((index, htmlElement) => {
  new YandexMap(htmlElement);
});

$('.load-more-reviews').each((index, htmlElement) => {
  new GenericLoadMoreReviewsComponent($(htmlElement), reviewsTemplate);
});

$('.load-more-simple-reviews').each((index, htmlElement) => {
  new GenericLoadMoreReviewsComponent($(htmlElement), simpleReviewsTemplate);
});

$('.calc-filter').each((index, htmlElement) => {
  new CalculatorFilterComponent($(htmlElement));
});

$('.range-and-checkboxes-wrapper').each((index, htmlElement) => {
  new RangeSliderComponent($(htmlElement));
});

initMagnificPopup();

$('.simple-tabs').on('click', 'li:not(.magic-line)', function() {
  $(this)
    .addClass('current')
    .siblings()
    .removeClass('current')
    .parents('.simple-tabs-wrapper')
    .find('.simple-tabs-box')
    .eq($(this).index())
    .fadeIn()
    .addClass('visible')
    .siblings('.simple-tabs-box')
    .hide()
    .removeClass('visible');
});

$('input[type=tel]:not(.wfpay-input-text)').each((index, htmlElement) => {
  $(htmlElement).mask('+7 (000) 000 00 00#', {
    onChange: function(value, event, field) {
      let valueDigits = value.replace(/\D/g, '');
      if (valueDigits.length > 11 && value.indexOf('+7 (8') === 0) {
        value = value.replace('+7 (8', '+7 (');
        field.val(field.masked(value));
      }

      // Выбрасываем событие, чтобы сообщить другим скриптам, что к значению инпута применена маска.
      // Не выбрасываем input или change, потому что это зацикливает плагин маски.
      let maskedEvent = new CustomEvent('masked');
      field[0].dispatchEvent(maskedEvent);
    },
  });
});
