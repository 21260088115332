import { Component, Fragment, h } from 'preact';

export default class DeliveryComponent extends Component {
  constructor(props) {
    super(props);
  }

  renderField(name, type, placeholder, require = false) {
    let value = '';
    if (this.props.state.deliveryForm) {
      value = this.props.state.deliveryForm[name];
    }
    let errors = [];
    if (this.props.state.errors && name in this.props.state.errors) {
      errors = this.props.state.errors[name];
    }
    return (
      <div class="field-item">
        <div class="field-item__field">
          <input
            type={type}
            name={name}
            value={value}
            class={errors.length > 0 ? 'error' : ''}
            oninput={(e) => this.props.handleChange(e)}
            // Событие masked выбрасывается после применения маски с помощью jquery-mask-plugin.
            // См. инициализацию jquery-mask-plugin.
            onmasked={(e) => this.props.handleChange(e)}
            placeholder={placeholder}
            required={require}
          />
          {errors.map((error) => {
            return (
              <label id={`${name}-error`} class="error" for={name}>
                {error}
              </label>
            );
          })}
        </div>
      </div>
    );
  }

  renderTextArea(name, placeholder, require = false) {
    let value = '';
    if (this.props.state.deliveryForm) {
      value = this.props.state.deliveryForm[name];
    }
    let errors = [];
    if (this.props.state.errors && name in this.props.state.errors) {
      errors = this.props.state.errors[name];
    }
    return (
      <div class="field-item__field">
        <textarea
          name={name}
          placeholder={placeholder}
          oninput={(e) => this.props.handleChange(e)}
          class={errors.length > 0 ? 'error' : ''}
          required={require}
        >
          {value}
        </textarea>
        {errors.map((error) => {
          return (
            <label id={`${name}-error`} class="error" for={name}>
              {error}
            </label>
          );
        })}
      </div>
    );
  }

  renderDeliveryType(delivery_type, index) {
    let value = null;
    let checked;
    if (this.props.state.deliveryForm) {
      value = this.props.state.deliveryForm['delivery_type'];
    }
    if (value || value === '0') checked = delivery_type.id.toString() === value;
    else checked = index === 0;
    return (
      <div class="form-st-checkbox">
        <label>
          {delivery_type.name}: <span class="price-bl">{delivery_type.price} ₽</span>
          <input
            type="radio"
            name="delivery_type"
            value={delivery_type.id}
            onchange={(e) => this.props.handleChange(e)}
            checked={checked}
          />
          <span class="fake-control" />
        </label>
      </div>
    );
  }

  renderPaymentType(payment_type) {
    let value = null;
    let checked = false;
    if (this.props.state.deliveryForm) {
      value = this.props.state.deliveryForm['payment_type'];
    }
    if (value) checked = payment_type.id.toString() === value;
    return (
      <div class="payment-type-group">
        <input
          id={`payment-type-${payment_type.id}`}
          type="radio"
          name="payment_type"
          checked={checked}
          onchange={(e) => this.props.handleChange(e)}
          value={payment_type.id}
        />
        <label for={`payment-type-${payment_type.id}`}>{payment_type.name}</label>
      </div>
    );
  }

  renderFormErrors() {
    let form_errors = [];
    if (this.props.state.errors && 'non_field_errors' in this.props.state.errors) {
      form_errors = this.props.state.errors['non_field_errors'];
    }
    if (form_errors.length === 0) {
      return;
    }

    return (
      <div class="field-item">
        {form_errors.map((error) => {
          return (
            <div class="field-item__field">
              <label id="form-error" class="error" for="form">
                {error}
              </label>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let payment_type_errors = [];
    if (this.props.state.errors && 'payment_type' in this.props.state.errors) {
      payment_type_errors = this.props.state.errors['payment_type'];
    }
    let delivery_type_errors = [];
    if (this.props.state.errors && 'delivery_type' in this.props.state.errors) {
      delivery_type_errors = this.props.state.errors['delivery_type'];
    }

    return (
      <form action="." method="POST">
        <section class="delivery-section">
          <div class="container">
            <h2>Доставка</h2>
            <div class="field-item">{this.renderField('fio', 'text', 'ФИО')}</div>
            <div class="field-item">{this.renderField('email', 'email', 'E-mail')}</div>
            <div class="field-item">
              {this.renderField('phone', 'tel', 'Телефон для связи', true)}
            </div>
            <div class="field-item">{this.renderTextArea('comment', 'Комментарий к заказу')}</div>
            <div class="delivery-form">
              <div class="left-part">
                <div class="form-del-check-wrapp">
                  {this.props.state.cartData.delivery_types.map((delivery_type, index) => {
                    return this.renderDeliveryType(delivery_type, index);
                  })}
                  {delivery_type_errors.map((error) => {
                    return (
                      <div class="field-item__field">
                        <label id="delivery_type-error" class="error" for="delivery_type">
                          {error}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div class="form-del-payment">
                  {this.props.state.cartData.payment_types.map((payment_type) => {
                    return this.renderPaymentType(payment_type);
                  })}
                </div>
                {payment_type_errors.map((error) => {
                  return (
                    <div class="field-item__field">
                      <label id="payment_type-error" class="error" for="payment_type">
                        {error}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div class="right-part">
                <div class="delivery-fields">
                  {this.renderField('index', 'text', 'Почтовый индекс')}
                  {this.renderField('city', 'text', 'Город')}
                  {this.renderTextArea('address', 'Адрес')}
                </div>
              </div>
            </div>
            {this.renderFormErrors()}
            <div class="bag-bottom">
              <div class="btn-mobile-max-w">
                <button class="btn" type="submit" onclick={(e) => this.props.handleCreateOrder(e)}>
                  Заказать
                </button>
              </div>
              <div class="bag-sum">
                Итого, с учетом доставки: {this.props.state.withDeliveryPrice} ₽
              </div>
            </div>
          </div>
        </section>
      </form>
    );
  }
}
