import { Component, h } from 'preact';

export default class TariffComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="my-bag-row">
        <button
          class="my-bag__close"
          type="button"
          onclick={(e) => this.props.onObjectDelete(e, this.props.item)}
        >
          <svg class="ico ico-mono-close">
            <use xlinkHref={`${this.props.sprite}#ico-mono-close`} />
          </svg>
        </button>
        <div class="my-bag-col my-bag-col_name">
          <a class="my-bag__img" href="#">
            <img src={this.props.item.tariff.provider.tariff_image} alt="" />
          </a>
          <a class="my-bag__title" href={this.props.item.tariff.absolute_url}>
            {this.props.item.tariff.name}
          </a>
        </div>
        <div class="my-bag-col my-bag-col_val">
          <input
            type="number"
            min={0}
            value={this.props.count}
            oninput={(e) => this.props.onCountChange(e, this.props.item)}
          />
          шт
        </div>
        <div class="my-bag-col my-bag-col_price">
          {this.props.item.tariff.connection_price * this.props.count} ₽
        </div>
      </div>
    );
  }
}
