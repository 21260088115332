export default class YandexMap {
  constructor(baseElement) {
    this.baseElement = baseElement;
    let latitude = this.baseElement.getAttribute('data-latitude');
    let longitude = this.baseElement.getAttribute('data-longitude');

    if (!latitude) {
      throw new Error('data-coordinates является обязательным атрибутом');
    }
    if (!longitude) {
      throw new Error('data-coordinates является обязательным атрибутом');
    }

    let pointName = this.baseElement.getAttribute('data-point-name') || '';
    if (window.ymaps) {
      window.ymaps.ready().then(() => {
        let map = new window.ymaps.Map(this.baseElement, {
          center: [latitude, longitude],
          zoom: 15,
        });
        map.geoObjects.add(
          new window.ymaps.Placemark(
            [latitude, longitude],
            {
              hintContent: pointName,
            },
            {
              preset: 'islands#greenDotIconWithCaption',
              iconColor: '#2e99fc',
            }
          )
        );
      });
    }
  }
}
