import lodashTemplate from 'lodash/template';
import forEach from 'lodash/forEach';
import AddToCartComponent from './AddToCartComponent';
import OneClickPurchase from './OneClickPurchase';
import tariffTemplate from '../templates/tariff.ejs';
import tariffListTemplate from '../templates/tariff_slides.ejs';

export default class CalculatorFilterComponent {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.isLoading = false;

    this.form = this.baseElement.find('form');

    this.targetBody = $(this.baseElement.attr('data-target'));
    this.targetSection = $(this.baseElement.attr('data-target-section'));

    this.submitters.on('click', (e) => {
      e.preventDefault();
      if (this.isLoading) return;
      this.isLoading = true;
      this.sendAjax()
        .then((data) => {
          if (data.results.length > 0) {
            this.targetSection.find('.title-site_h2').text('Подходящие тарифы:');
            let html = this.renderTariffs(data.results);
            this.targetBody.html(html);
            this.targetBody.find('.add-to-cart-component').each((index, htmlElement) => {
              new AddToCartComponent($(htmlElement));
            });
            this.targetBody.find('.one-click-purchase').each((index, htmlElement) => {
              new OneClickPurchase(htmlElement);
            });
          } else {
            this.targetBody.empty();
            this.targetSection
              .find('.title-site_h2')
              .text('Сожалеем, но нам не удалось найти тариф, подходящий под ваши условия.');
          }
          this.targetSection.show();
        })
        .fail((error) => {
          throw error;
        })
        .always(() => {
          this.isLoading = false;
        });
    });
  }

  get submitters() {
    return this.form.find(':submit');
  }

  renderTariffs(tariffs) {
    let tariffsList = [];
    for (let i = 0; i < tariffs.length; i++) {
      tariffsList.push(this.preparedObjTemplate({ tariff: tariffs[i] }));
    }

    return this.preparedListTemplate({ tariffs: tariffsList });
  }

  get preparedObjTemplate() {
    return lodashTemplate(tariffTemplate, {
      imports: {
        forEach: forEach,
      },
    });
  }

  get preparedListTemplate() {
    return lodashTemplate(tariffListTemplate, {
      imports: {
        forEach: forEach,
      },
    });
  }

  get url() {
    let actionURL = new URL(window.location.origin + this.form.attr('action'));
    actionURL.search = new URLSearchParams(this.searchParams).toString();
    return actionURL;
  }

  get searchParams() {
    let paramsArray = this.form.serializeArray();

    let paramsDict = {};
    for (let i = 0; i < paramsArray.length; i++) {
      let name = paramsArray[i].name;
      let value = paramsArray[i].value;

      if (name in paramsDict) {
        paramsDict[name] = `${paramsDict[name]}:${value}`;
      } else {
        paramsDict[name] = value;
      }
    }
    return $.param(paramsDict);
  }

  sendAjax() {
    return $.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'json',
      encode: true,
      processData: false,
      contentType: false,
    });
  }
}
