import { openPopup } from './magnific-popup';
import { prepareTariffForDataLayer, pushOrderToDataLayer } from '../utils';

const MODAL_SELECTOR = '#modalOneClickPurchase';

export default class OneClickPurchase {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.baseElement.addEventListener('click', (event) => {
      event.preventDefault();
      this.onClick();
    });
  }

  get tariffId() {
    return this.baseElement.getAttribute('data-tariff-id');
  }

  get tariffName() {
    return this.baseElement.getAttribute('data-tariff-name');
  }

  onClick() {
    let modal = document.querySelector(MODAL_SELECTOR);
    if (modal) {
      let tariffIdInput = modal.querySelector('[name=tariff]');
      if (tariffIdInput && this.tariffId) {
        tariffIdInput.value = this.tariffId;
      }

      let tariffNameInput = modal.querySelector('[name=tariff_name]');
      if (tariffNameInput && this.tariffName) {
        tariffNameInput.value = this.tariffName;
      }

      openPopup(MODAL_SELECTOR);
    }
  }
}

export function initOneClickPurchaseModal() {
  let modal = document.querySelector(MODAL_SELECTOR);
  if (modal) {
    modal.addEventListener('form-component.submit', (event) => {
      if (event.detail.isValid) {
        let preparedTariff = prepareTariffForDataLayer(event.detail.responseData.tariff, 1);
        pushOrderToDataLayer(
          [preparedTariff],
          event.detail.responseData.order_id,
          event.detail.responseData.order_sum
        );
      }
    });
  }
}
