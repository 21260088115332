import { Component, h } from 'preact';
import TariffComponent from './TariffComponent';

export default class CartItemComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TariffComponent
        item={this.props.item}
        sprite={this.props.sprite}
        count={this.props.item.count}
        onCountChange={this.props.onCountChange}
        onObjectDelete={this.props.onObjectDelete}
      />
    );
  }
}
