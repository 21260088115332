const stickyModule = {
  init: () => {
    // elements
    var $startEl = $('[data-sticky-start]');
    var $targetEl = $('[data-sticky]');
    var $destinationEl = $targetEl.parent();

    // if elements doesn't on page
    if (!$targetEl.length || !$destinationEl.length || !$startEl.length) {
      return;
    }

    // positions
    var resizeTimer, startBottomPosition, destinationBottomPosition;

    // events
    $(window).scroll(stickyHandler);

    $(window).resize(function() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        calculatePositions();
        stickyHandler();
      }, 100);
    });

    // init
    $(window).trigger('resize');

    // calc positions
    function calculatePositions() {
      startBottomPosition = $startEl.offset().top + $startEl.height();
      destinationBottomPosition =
        $destinationEl.offset().top + $destinationEl.height() - $(window).height();
    }

    function stickyHandler() {
      var startPos = startBottomPosition - $(window).scrollTop();
      var destinationPos = destinationBottomPosition - $(window).scrollTop();

      if (startPos < 0) {
        $targetEl.addClass('animated');
      } else {
        $targetEl.removeClass('animated');
      }

      if (startPos < 0 && destinationPos > 0) {
        $targetEl.addClass('sticky-prod');
      } else {
        $targetEl.removeClass('sticky-prod');
      }
    }
  },
};

export default stickyModule;
