const layoutModule = {
  init: () => {
    // minHeight box items
    function setMinHeightBoxTab() {
      var mHeightMainBox = 0;
      $('.main-icons-section .simple-tabs-box').each(function() {
        var h = $(this).outerHeight();
        if (h > mHeightMainBox) {
          mHeightMainBox = h;
        }
      });

      $('.main-icons-section .simple-tabs-box').css('minHeight', mHeightMainBox);
    }

    setMinHeightBoxTab();

    // magic line
    $('.main-icons.simple-tabs, .product-more-info .simple-tabs').each(function() {
      var $tabsWrap = $(this);
      $tabsWrap.append('<li class="magic-line"></li>');
      var $magicLine = $tabsWrap.find('.magic-line');

      $tabsWrap.children('li').on('click', function() {
        var $el = $(this);
        $magicLine.stop().animate(
          {
            left: $el.position().left,
            width: $el.outerWidth(),
          },
          300
        );
      });

      $tabsWrap.children('li:first-child').click();
    });

    // resize window
    $(window).resize(function() {
      setTimeout(function() {
        setMinHeightBoxTab();
      }, 100);
    });
  },
};

export default layoutModule;
