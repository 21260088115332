export const AnimationTypes = {
  DEFAULT: 'default',
  BUTTON: 'button',
};

export default class AnimationComponent {
  constructor(baseElement, animationType = 'default') {
    this.baseElement = baseElement;
    this.animationType = animationType;

    this.animate();
  }

  animate() {
    if (this.animationType === AnimationTypes.DEFAULT) {
      this.baseElement.children().hide();
      this.baseElement.append(`<div class="animation-wrapper">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </div>`);
    }
    if (this.animationType === AnimationTypes.BUTTON) {
      this.baseElement.toggleClass('sending');
    }
  }

  destroy() {
    if (this.animationType === AnimationTypes.DEFAULT) {
      this.baseElement.children().show();
      this.baseElement.find('.animation-wrapper').remove();
    }
    if (this.animationType === AnimationTypes.BUTTON) {
      this.baseElement.toggleClass('sending');
    }
  }
}
