import { h, render } from 'preact';
import CartComponent from './CartComponent';

export function renderCartComponent(element) {
  let dataJson = element.getAttribute('data-items');
  if (!dataJson) {
    throw new Error("Element doesn't have data-items attribute");
  }
  let data = JSON.parse(dataJson);
  let svgSprite = element.getAttribute('data-svg');
  if (!svgSprite) {
    throw new Error("Element doesn't have data-svg attribute");
  }

  let orderURL = element.getAttribute('data-order-url');
  if (!orderURL) {
    throw new Error("Element doesn't have data-order-url attribute");
  }

  let hasCleanItems = element.getAttribute('data-clean-items') === 'True';
  let props = {
    data: data,
    sprite: svgSprite,
    orderURL: orderURL,
    hasCleanItems: hasCleanItems,
  };

  render(<CartComponent {...props} />, element);
}
