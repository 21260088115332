import $ from 'jquery';
import 'magnific-popup';
import { toggleDocumentScrollBlocker } from '../utils';

const DEFAULT_MFP_OPTIONS = {
  midClick: true,
  mainClass: 'mfp-zoom',
  removalDelay: 300,
  cursor: null,
  callbacks: {
    open: toggleDocumentScrollBlocker,
    close: toggleDocumentScrollBlocker,
  },
};

export function initMagnificPopup() {
  $('.open-mfp-btn').magnificPopup({
    ...DEFAULT_MFP_OPTIONS,
    type: 'inline',
  });

  $(document).on('click', '.btn-modal-close', (e) => {
    e.preventDefault();
    $.magnificPopup.close();
  });
}

/**
 * @param {any} src CSS-селектор, HTML или объект jQuery.
 */
export function openPopup(src) {
  $.magnificPopup.open({
    ...DEFAULT_MFP_OPTIONS,
    items: {
      type: 'inline',
      src: src,
    },
  });
}
