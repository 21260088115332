import lodashTemplate from 'lodash/template';
import forEach from 'lodash/forEach';

export default class GenericLoadMoreReviewsComponent {
  constructor(baseElement, template) {
    this.baseElement = baseElement;
    this.template = template;

    this.data = this.baseElement.data();

    if (!this.data.url) {
      throw new Error('data-url является обязательным атрибутом');
    }
    if (!this.data.target) {
      throw new Error('data-target является обязательным аргументом');
    }

    if (!this.data.answerUsername) {
      throw new Error('data-answer-username является обязательным атрибутом');
    }

    if (!this.data.pageSize) {
      this.data.pageSize = 5;
    }

    this.targetBody = $(this.data.target);

    this.isLoading = false;
    this.page = 2;

    this.baseElement.on('click', (e) => {
      e.preventDefault();

      if (this.isLoading) return;
      this.sendAjax()
        .then((data) => {
          this.page += 1;
          let html = this.preparedTemplate(this.templateData(data.results));
          this.targetBody.append(html);
          if (!data.next) {
            this.baseElement.hide();
          }
        })
        .fail((error) => {
          throw error;
        })
        .always(() => {
          this.isLoading = false;
        });
    });
  }

  templateData(reviews) {
    return {
      reviews: reviews,
      data: this.data,
    };
  }

  get preparedTemplate() {
    return lodashTemplate(this.template, {
      imports: {
        forEach: forEach,
      },
    });
  }

  get url() {
    let paramsDict = {
      page: this.page,
      // eslint-disable-next-line camelcase
      page_size: this.data.pageSize,
    };
    let result = `${this.data.url}?${$.param(paramsDict)}`;
    if (this.data.extraParams) return `${result}${this.data.extraParams}`;
    return result;
  }

  sendAjax() {
    return $.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'json',
      encode: true,
      processData: false,
      contentType: false,
    });
  }
}
