import AnimationComponent, { AnimationTypes } from './AnimationComponent';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_ru';

import Cookies from "js-cookie";

export default class FormComponent {
  constructor(baseElement) {
    this.baseElement = baseElement;

    this.validator = this.baseElement.validate({
      rules: {
        email: {
          email: true,
        },
        phone: {
          normalizer: (value) => value.replace(/\D/g, ''),
          minlength: 11,
          maxlength: 11,
        },
      },
      messages: {
        phone: {
          required: 'Укажите номер',
          minlength: 'Введите корректный телефон',
          maxlength: 'Введите корректный телефон',
        },
      },
    });

    this.isLoading = false;

    this.data = this.baseElement.data();
    this.successWindow = '#modalSuccess';

    if (this.data.successWindow) {
      this.successWindow = this.data.successWindow;
    }

    this.submitButton.on('click', (e) => {
      e.preventDefault();
      if (this.isLoading) return;
      if (!this.baseElement.valid()) return;
      let formData = new FormData(this.baseElement[0]);
      this.isLoading = true;
      let animation = new AnimationComponent(this.submitButton, AnimationTypes.BUTTON);
      $.ajax({
        type: 'POST',
        url: this.url,
        data: formData,
        dataType: 'json',
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        encode: true,
        processData: false,
        contentType: false,
      })
        .then((data) => {
          if (data.status === 'ok') {
            $.magnificPopup.open(
              {
                items: {
                  src: this.successWindow,
                },
              },
              0
            );
            this.baseElement[0].reset();
          } else {
            let errors = prepareFormErrors(data.message);
            this.baseElement.addClass('invalid-form');
            mapErrorsToForm(errors, this.validator);
          }

          this.emitSubmitEvent(data);
        })
        .fail((error) => {
          throw error;
        })
        .always(() => {
          this.baseElement.removeClass('invalid-form');
          this.isLoading = false;
          animation.destroy();
        });
    });
  }

  get url() {
    return this.baseElement.attr('action');
  }

  get submitButton() {
    return this.baseElement.find(':submit');
  }

  emitSubmitEvent(responseData) {
    let event = new CustomEvent('form-component.submit', {
      detail: {
        responseData: responseData,
        isValid: responseData.status === 'ok',
      },
      bubbles: true,
    });
    this.baseElement[0].dispatchEvent(event);
  }
}

function mapErrorsToForm(errors, $validator) {
  $validator.showErrors(errors);
}

function prepareFormErrors(formErrors) {
  let result = {};
  for (const key in formErrors) {
    let errors = formErrors[key];
    let strError = '';
    for (let i = 0; i < errors.length; i++) {
      strError += errors[i].message + ' ';
    }
    result[key] = strError;
  }
  return result;
}
