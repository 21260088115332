import $ from 'jquery';
import { toggleDocumentScrollBlocker } from '../utils';

$(document).on('click', '[href="#"]', (e) => {
  e.preventDefault();
});

//scroll to slow
$('a.scrollto').on('click', function() {
  let elementClick = $(this).attr('href');
  let destination = $(elementClick).offset().top;
  jQuery('html:not(:animated),body:not(:animated)').animate({ scrollTop: destination }, 800);
  return false;
});

// click all product char link
$('.all-char-link_js-click').on('click', () => {
  $('#allChar').click();
});

$('.burger-menu').on('click', () => {
  $('body').addClass('menu_opened');
});

// close mobile menu
$('.burger-close').on('click', () => {
  $('body').removeClass('menu_opened');
});

$('.submenu-component').on('click', (e) => {
  $(e.target).parents('li').toggleClass('opened').find('ul').first().slideToggle(200);
});

$('.submenu-mobile-component').on('click', (e) => {
  $(e.target).toggleClass('opened').find('ul').first().slideToggle(200);
});

$(document).bind('click touchstart', (e) => {
  let $clicked = $(e.target);
  if (!$clicked.parents().hasClass('mobile-menu-wrapper')) {
    $('body').removeClass('menu_opened');
  }
});

$(document).bind('click touchstart', (e) => {
  let $clicked = $(e.target);
  if (!$clicked.parents().hasClass('menu__dd')) {
    $('.menu__dd').removeClass('opened').find('ul').stop().slideUp(200);
  }
});

$('.faq-header').on('click', function() {
  const $parent = $(this).parent();
  $parent.toggleClass('opened');
  $parent.find('.faq-body').slideToggle(200);
});

$('.filter-mob-btn').on('click', () => {
  toggleDocumentScrollBlocker();
  $('body').addClass('filter_open');
});

$('.filter-close').on('click', () => {
  toggleDocumentScrollBlocker();
  $('body').removeClass('filter_open');
});

$('.filter-items').on('click', '.filter-item__tt', function() {
  $(this).toggleClass('open').siblings('.filter-item-options').slideToggle();
});
