import Cookies from 'js-cookie';
import AnimationComponent, { AnimationTypes } from './AnimationComponent';
import { prepareTariffForDataLayer, pushTariffsToDataLayer } from '../utils';
import { setCartButtonsNonEmpty } from './CartButton';

export default class AddToCartComponent {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.url = this.baseElement.attr('data-add-to-cart');
    this.replacedURL = this.baseElement.attr('data-cart-url');

    this.isLoading = false;

    this.button.on('click', (e) => {
      if (this.button.is('[disabled]')) {
        return;
      }
      if (this.button.attr('href') === this.replacedURL) {
        return;
      }

      e.preventDefault();

      if (this.isLoading) return;
      this.isLoading = true;

      let animation = new AnimationComponent(this.button, AnimationTypes.BUTTON);
      let preparedData1 = this.preparedData;
      this.sendAjax(preparedData1)
        .then((data) => {
          this.button.attr('href', this.replacedURL);
          this.button.text('Перейти в корзину');

          let tariffs = prepareTariffForDataLayer(data, Number(preparedData1.count));
          pushTariffsToDataLayer('add', [tariffs]);

          setCartButtonsNonEmpty();
        })
        .fail((error) => {
          throw error;
        })
        .always(() => {
          this.isLoading = false;
          animation.destroy();
        });
    });
  }

  get preparedData() {
    let counterValue = 1;
    if (this.counterInput.length > 0) {
      counterValue = this.counterInput.val();
    }
    return { count: counterValue, tariff: this.baseElement.attr('data-id') };
  }

  get counterInput() {
    return this.baseElement.find('input[name="count"]');
  }

  get button() {
    // Очень на тоненького, может придумать иной способ определения кнопки
    if (this.baseElement.is('.btn')) {
      return this.baseElement;
    }
    return this.baseElement.find('.btn');
  }

  sendAjax(data) {
    return $.ajax({
      type: 'POST',
      url: this.url,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      encode: true,
      processData: false,
    });
  }
}
